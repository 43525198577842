import { FlexBuffer } from './flex-buffer.ts';
import { SerializableAssetIndex, SerializableAssetIndexLike } from './serializable-asset-index.ts';
import { Counter } from '../data-structures/counter.ts';
import { Serializable } from './serializable.ts';
import { DataType, MAX_UINT_32, SERIALIZABLE } from './serialization-constants.ts';
import { getFunctionArgumentNames, getFunctionBody } from '../language/function.ts';

export class Serializer {
    private serializableAssets: SerializableAssetIndex;
    private valueToId: Map<any, number> = new Map();
    private idCounter: Counter = new Counter();
    private internalBuffer: FlexBuffer = new FlexBuffer();
    private buffer: FlexBuffer = this.internalBuffer;
    private cachedFunctionPointers: Map<Function, [number, number]> = new Map();

    constructor(serializableAssets?: SerializableAssetIndexLike) {
        this.serializableAssets = SerializableAssetIndex.from(serializableAssets);
    }

    serialize<T>(value: T, buffer?: FlexBuffer): FlexBuffer {
        this.internalBuffer.reset();
        this.buffer = buffer ?? this.internalBuffer;
        this.valueToId.clear();
        this.idCounter.reset();
        this.writeAny(value);
        this.valueToId.clear(); // clear map so keys can be garbage collected

        return this.buffer;
    }

    private writeAny(value: any) {
        if (value === undefined) {
            this.buffer.writeUint8(DataType.Undefined);
        } else if (value === null) {
            this.buffer.writeUint8(DataType.Null);
        } else if (value === true) {
            this.buffer.writeUint8(DataType.True);
        } else if (value === false) {
            this.buffer.writeUint8(DataType.False);
        } else if (typeof value === 'number') {
            this.buffer.writeUint8(DataType.Number);
            this.buffer.writeFloat64(value);
        } else if (typeof value === 'bigint') {
            this.buffer.writeUint8(DataType.BigInt);
            this.buffer.writeInt64(value);
        } else if (typeof value === 'string') {
            this.buffer.writeUint8(DataType.String);
            this.writeString(value);
        } else if (this.serializableAssets.has(value)) {
            this.buffer.writeUint8(DataType.StaticAsset);
            this.writeStaticAsset(value);
        } else if (value instanceof Map) {
            this.buffer.writeUint8(DataType.Map);
            this.writeMap(value);
        } else if (value instanceof Set) {
            this.buffer.writeUint8(DataType.Set);
            this.writeSet(value);
        } else if (Array.isArray(value)) {
            this.buffer.writeUint8(DataType.Array);
            this.writeArray(value);
        } else if (value instanceof ArrayBuffer || ArrayBuffer.isView(value)) {
            this.buffer.writeUint8(DataType.Buffer);
            if (value instanceof Uint8Array) {
                this.writeBuffer(value);
            } else {
                throw new Error(`TODO: cannot yet serialize non-Uint8Array (${value.constructor.name})`);
            }
        } else if (value instanceof Promise) {
            this.buffer.writeUint8(DataType.Promise);
        } else if (typeof value === 'object') {
            this.buffer.writeUint8(DataType.Object);
            this.writeObject(value);
        } else if (typeof value === 'function') {
            this.buffer.writeUint8(DataType.Function);
            this.writeFunction(value);
        } else if (typeof value === 'symbol') {
            this.buffer.writeUint8(DataType.Symbol);
        } else {
            console.log(value);
            throw new Error(`unreachable`);
        }
    }
    
    private writeString(string: string) {
        let id = this.valueToId.get(string);

        if (!id) {
            id = this.idCounter.next();
            this.valueToId.set(string, id);
            this.buffer.writeUint32(id);
            this.buffer.writeString(string);
        } else {
            this.buffer.writeUint32(id);
        }
    }

    private writeArray(array: any[]) {
        let id = this.valueToId.get(array);

        if (!id) {
            id = this.idCounter.next();
            this.valueToId.set(array, id);
            this.buffer.writeUint32(id);
            this.buffer.writeUint32(array.length);

            for (let item of array) {
                this.writeAny(item);
            }
        } else {
            this.buffer.writeUint32(id);
        }
    }

    private writeObject(object: object) {
        let id = this.valueToId.get(object);

        if (!id) {
            id = this.idCounter.next();
            this.valueToId.set(object, id);

            this.buffer.writeUint32(id);

            let serializableProperty = (object as any)[SERIALIZABLE];

            if (serializableProperty === false) {
                this.buffer.writeUint32(MAX_UINT_32);
            } else {
                let shouldSerializeProperty: (prop: string) => boolean = () => true;

                if (typeof serializableProperty === 'function') {
                    shouldSerializeProperty = (prop: string) => serializableProperty.call(object, prop)
                }

                let classId = this.serializableAssets.getIdFromAsset(object.constructor) || 0;
                let className = object.constructor === Object ? '' : (object.constructor?.name ?? '');

                this.buffer.writeUint32(classId);
                this.writeString(className);

                if (classId && 'serialize' in object && 'deserialize' in object && typeof object.serialize === 'function') {
                    (object as Serializable).serialize(this.buffer);
                } else {
                    let entries = Object.entries(object);

                    this.buffer.writeUint16(entries.length);

                    for (let [key, value] of entries) {
                        this.writeString(key);

                        if (shouldSerializeProperty(key)) {
                            this.writeAny(value);
                        } else {
                            this.writeAny(undefined);
                        }
                    }
                }
            }
        } else {
            this.buffer.writeUint32(id);
        }
    }

    private writeSet(set: Set<any>) {
        this.writeSetOrMap(set);
    }

    private writeMap(map: Map<any, any>) {
        this.writeSetOrMap(map);
    }

    private writeBuffer(buffer: Uint8Array) {
        let id = this.valueToId.get(buffer);

        if (!id) {
            id = this.idCounter.next();
            this.valueToId.set(buffer, id);
            this.buffer.writeUint32(id);
            this.buffer.writeBuffer(buffer);
        } else {
            this.buffer.writeUint32(id);
        }
    }

    private writeStaticAsset(value: any) {
        let assetId = this.serializableAssets.getIdFromAsset(value) ?? 0;

        this.buffer.writeUint32(assetId);
    }

    private writeSetOrMap(value: Set<any> | Map<any, any>) {
        let id = this.valueToId.get(value);

        if (!id) {
            id = this.idCounter.next();
            this.valueToId.set(value, id);

            this.buffer.writeUint32(id);
            this.writeArray(Array.from(value));
        } else {
            this.buffer.writeUint32(id);
        }
    }

    private writeFunction(func: (...args: any[]) => any) {
        let entry = this.cachedFunctionPointers.get(func) ?? null;

        if (!entry) {
            entry = this.searchFunction(func);

            if (entry) {
                this.cachedFunctionPointers.set(func, entry);
            }
        }

        if (entry) {
            this.buffer.writeUint32(entry[0]);
            this.buffer.writeUint32(entry[1]);
        } else {
            this.buffer.writeUint32(0);

            // let id = this.valueToId.get(func);

            // if (!id) {
            //     id = this.idCounter.next();
            //     let args = getFunctionArgumentNames(func);
            //     let body = getFunctionBody(func);

            //     this.buffer.writeUint32(id);
            //     this.buffer.writeUint32(args.length);

            //     for (let arg of args) {
            //         this.buffer.writeString(arg);
            //     }

            //     this.buffer.writeString(body);
            // } else {
            //     this.buffer.writeUint32(id);
            // }
        }
    }

    private searchFunction(func: Function): [number, number] | null {
        for (let [id, asset] of this.serializableAssets.entries()) {
            if (typeof asset !== 'function') {
                continue;
            }

            let index = 0;

            for (let key of Object.getOwnPropertyNames(asset)) {
                let value = (asset as any)[key];

                if (value === func) {
                    return [id, index];
                }

                index += 1;
            }
        }

        return null;
    }
}
globalThis.ALL_FUNCTIONS.push(Serializer);