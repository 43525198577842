import { applyEasing, Easing } from './easing.js';

export type TimeFrameLike =
    | TimeFrame
    | number
    | null

export class TimeFrame {
    startTime: number;
    duration: number;
    easing: Easing;

    static readonly DEFAULT = new TimeFrame();

    constructor(startTime: number = 0, duration: number = 0, easing: Easing = 'linear') {
        this.startTime = startTime;
        this.duration = duration;
        this.easing = easing;
    }

    copyFrom(source: TimeFrame) {
        this.startTime = source.startTime
        this.duration = source.duration
        this.easing = source.easing
    }

    reset(startTime: number = 0, duration: number = 0, easing: Easing = 'linear'): TimeFrame {
        this.startTime = startTime;
        this.duration = duration;
        this.easing = easing;
        
        return this;
    }

    hasStarted(currentTime: number): boolean {
        return currentTime >= this.startTime;
    }

    getAnimationRatio(currentTime: number): number {
        if (currentTime >= this.startTime + this.duration) {
            return 1;
        } else if (currentTime <= this.startTime) {
            return 0;
        } else if (!isFinite(this.duration)) {
            return 0.5;
        } else {
            return applyEasing(this.easing, (currentTime - this.startTime) / this.duration)
        }
    }

    getEndTime(): number {
        return this.startTime + this.duration;
    }

}
globalThis.ALL_FUNCTIONS.push(TimeFrame);