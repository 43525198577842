import { mapObjectValues } from '../language/object.ts';
import { NumberRange, getRangeEnd, getRangeStart } from '../language/range.ts';
import { Constructor, Properties } from '../language/types.ts';
import { optionalSchema } from './optional-schema.ts';
import { TypeSchemaLike, formatTypeSchema } from './type-schema-like.ts';
import { TypeKind, TypeSchema } from './type-schema.ts';

export type ObjectSchemaOptions<T extends object> = {
    ctor?: Constructor<T> | null,
    keyCount?: NumberRange;
};

export function objectSchema<T extends object>(
    properties: { [Key in keyof Properties<T>]: TypeSchemaLike<T[Key]> },
    options: ObjectSchemaOptions<T> | Constructor<T> = {}
): TypeSchema<T> {
    let fmtOptions: ObjectSchemaOptions<T> = typeof options === 'function' ? { ctor: options } : options;
    let constructor = fmtOptions.ctor ?? null;
    let minKeyCount = getRangeStart(fmtOptions.keyCount, 0);
    let maxKeyCount = getRangeEnd(fmtOptions.keyCount, Infinity);

    return {
        kind: TypeKind.Other,
        optional: false,
        check(ctx, value) {
            if (!value || typeof value !== 'object' || Array.isArray(value)) {
                return ctx.expected('object', value);
            }

            let ok = true;
            let keyCount = 0;

            if (constructor && !(value instanceof constructor)) {
                ctx.error(`expected constructor ${constructor.name}, got ${value.constructor.name}`);
                ok = false;
            }

            for (let [key, itemSchema] of iterateProperties(properties)) {
                if (!(key in value) && !itemSchema.optional) {
                    ctx.error(`missing property "${key}"`);
                    ok = false;
                    continue;
                }

                let item = (value as any)[key];

                if (!itemSchema.check(ctx.property(key), item)) {
                    ok = false;
                }
            }

            for (let inputProperty in value) {
                keyCount += 1;
                if (!(inputProperty in properties)) {
                    ctx.error(`unkown property "${inputProperty}"`);
                    ok = false;
                }
            }

            if (ok && (keyCount < minKeyCount || keyCount > maxKeyCount)) {
                let str = minKeyCount === maxKeyCount ? `exactly ${minKeyCount}` : `between ${minKeyCount} and ${maxKeyCount}`;
                let s = maxKeyCount > 1 ? 's' : '';

                ctx.error(`expected ${str} key${s}, got ${keyCount}`);
                ok = false;
            }

            return ok;
        },
        serialize(buffer, value) {
            for (let [key, itemType] of iterateProperties(properties)) {
                let item = (value as any)[key];

                itemType.serialize(buffer, item);
            }
        },
        deserialize(buffer) {
            let result = constructor ? Object.create(constructor.prototype) : {} as any;

            for (let [key, itemType] of iterateProperties(properties)) {
                result[key] = itemType.deserialize(buffer);
            }

            return result;
        },
    };
}

function iterateProperties(properties: { [key: string]: TypeSchemaLike<any>; }): [string, TypeSchema<any>][] {
    return Object.entries(properties).map(([key, value]) => [key, formatTypeSchema(value)]);
}

export function partialObjectSchema<T extends object>(
    properties: { [Key in keyof Required<Properties<T>>]: TypeSchemaLike<Exclude<T[Key], undefined>> }
): TypeSchema<T> {
    return objectSchema(mapObjectValues(properties, value => optionalSchema(value)));
}