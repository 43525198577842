import { Server } from './server/server.ts';
import { Client } from './client/client.ts';
import { sleep } from '../utils/language/async.ts';
import { enableGlobalHooks } from './global/global-hooks.ts';
import { Component } from './component/component.ts';

export type StartGameParams = {
    createRootRoom: () => Component;
}

/**
 * Entry point of the framework, both on the server and the client.
 * - On the server, it returns a Promise that resolves when it is ready to accept client connections.
 * - On the client, it returns a Promise that resolves when it is connected to the server.
 * The URL of the server is deduced from [window.location](https://developer.mozilla.org/en-US/docs/Web/API/Window/location)
 * and some data embedded in the build.
 * @category Core
 * @param rootComponent
 * @example
 * import { startGame, Color, Component, ComponentApi, ComponentEvent, ItemMap, View } from 'outpost';
 * 
 * async function main() {
 *     await startGame(new RootComponent());
 * 
 *     console.log('Game is up and running.');
 * }
 * 
 * const AddPlayerEvent = ComponentEvent({
 *     id: 'string',
 *     x: 'number',
 *     y: 'number',
 * });
 * 
 * const RemovePlayerEvent = ComponentEvent({
 *     id: 'string',
 * });
 * 
 * export class RootComponent implements Component {
 *     items = {
 *         players: new ItemMap<Player>()
 *     };
 * 
 *     onPlayerConnectedServer(api: ComponentApi): void {
 *         // Displayed server-side only.
 *         console.log(`Player ${api.getClientId()} just connected.`);
 * 
 *         for (let player of this.items.players.values()) {
 *             let event = new AddPlayerEvent({
 *                 id: player.id,
 *                 x: player.x,
 *                 y: player.y
 *             });
 * 
 *             // Notify the newly connected player of each player already connected.
 *             api.emitEvent(event, {
 *                 emitToPlayers: [api.getClientId()],
 *                 emitToServer: false,
 *             });
 *         }
 * 
 *         // Position the new player at a random position in the default viewport.
 *         let addPlayerEvent = new AddPlayerEvent({
 *             id: api.getClientId(),
 *             x: Math.random() * 1600,
 *             y: Math.random() * 900,
 *         });
 * 
 *         // Notify everyone of the new player.
 *         api.emitEvent(addPlayerEvent);
 *     }
 * 
 *     onPlayerDisconnectedServer(api: ComponentApi): void {
 *         // Displayed server-side only.
 *         console.log(`Player ${api.getClientId()} just disconnected.`);
 * 
 *         // Notify everyone that a player disconnected.
 *         api.emitEvent(new RemovePlayerEvent({
 *             id: api.getClientId(),
 *         }));
 *     }
 * 
 *     onEvent(api: ComponentApi): void {
 *         // This is executed both on the server and the client when an event is received.
 *         let event = api.getEvent();
 * 
 *         if (event instanceof AddPlayerEvent) {
 *             this.items.players.set(event.id, new Player(event.id, event.x, event.y));
 *         } else if (event instanceof RemovePlayerEvent) {
 *             this.items.players.delete(event.id);
 *         }
 * 
 *         // Animate the player addition or removal over 300 ms.
 *         api.queue().render(300);
 *     }
 * 
 *     async runInteraction(api: ComponentApi): Promise<void> {
 *         await api.waitForKeyPress('Enter');
 * 
 *         await api.waitForServerResponse();
 * 
 *         // Displayed both server-side and client-side (only for the player who pressed the key)
 *         console.log(`Player ${api.getClientId()} pressed Enter.`);
 * 
 *         // At this point, you could emit an event to notify everyone of the player's action.
 *     }
 * 
 *     render(view: View): void {
 *         view.paint()
 *             .backgroundColor('white')
 * 
 *         view.renderChild(this.items.players);
 *     }
 * }
 * 
 * class Player implements Component {
 *     id: string;
 *     x: number;
 *     y: number;
 * 
 *     constructor(id: string, x: number, y: number) {
 *         this.id = id;
 *         this.x = x;
 *         this.y = y;
 *     }
 * 
 *     render(view: View): void {
 *         view.paint()
 *             .x(this.x)
 *             .y(this.y)
 *             .width(100)
 *             .height(100)
 *             .shape('circle')
 *             .backgroundColor(Color.fromStringHash(this.id))
 *     }
 * 
 *     renderBirth(view: View): void {
 *         // Make so the player grows from a single point when it is added
 *         view.paint()
 *             .transform(rect => rect.scale(0))
 *     }
 * 
 *     renderDeath(view: View): void {
 *         // Make so the player shrinks down to a single point when it is removed.
 *         view.paint()
 *             .transform(rect => rect.scale(0))
 *     }
 * }
 * 
 * main();
 */
export async function startGame(params: StartGameParams | StartGameParams['createRootRoom']) {
    let fmtParams: StartGameParams = typeof params === 'function' ? { createRootRoom: params } : params;

    enableGlobalHooks();

    await sleep(0);

    if (typeof window !== 'undefined') {
        let client = new Client(fmtParams);

        (window as any).client = client;

        await client.start();
    } else {
        let server = new Server(fmtParams);

        await server.start();
    }
}