const DEFAULT_START_CAPACITY = 64;

export type FlexBufferLike = FlexBuffer | ArrayBuffer | ArrayBufferView;

export class FlexBuffer {
    buffer: ArrayBuffer;
    byteOffset: number;
    byteLength: number;
    writeByteIndex: number;
    readByteIndex: number;
    dataView: DataView;

    constructor(flexBufferLikeOrCapacity: FlexBufferLike | number = DEFAULT_START_CAPACITY) {
        if (typeof flexBufferLikeOrCapacity === 'number') {
            this.buffer = new ArrayBuffer(flexBufferLikeOrCapacity);
            this.byteOffset = 0;
            this.byteLength = flexBufferLikeOrCapacity;
            this.writeByteIndex = 0;
        } else if (flexBufferLikeOrCapacity instanceof ArrayBuffer) {
            this.buffer = flexBufferLikeOrCapacity;
            this.byteOffset = 0;
            this.byteLength = flexBufferLikeOrCapacity.byteLength;
            this.writeByteIndex = this.byteLength;
        } else {
            this.buffer = flexBufferLikeOrCapacity.buffer;
            this.byteOffset = flexBufferLikeOrCapacity.byteOffset;
            this.byteLength = flexBufferLikeOrCapacity.byteLength;
            this.writeByteIndex = this.byteLength;
        }
        
        this.readByteIndex = 0;
        this.dataView = new DataView(this.buffer, this.byteOffset, this.byteLength);
    }

    static from(flexBufferLike: FlexBufferLike) {
        if (flexBufferLike instanceof FlexBuffer) {
            return flexBufferLike;
        } else {
            return new FlexBuffer(flexBufferLike);
        }
    }

    reset() {
        this.writeByteIndex = 0;
        this.readByteIndex = 0;
    }

    writeInt8(value: number) {
        this.extendIfNecessary(1);
        this.dataView.setInt8(this.writeByteIndex, value);
        this.writeByteIndex += 1;
    }

    writeInt16(value: number) {
        this.extendIfNecessary(2);
        this.dataView.setInt16(this.writeByteIndex, value);
        this.writeByteIndex += 2;
    }

    writeInt32(value: number) {
        this.extendIfNecessary(4);
        this.dataView.setInt32(this.writeByteIndex, value);
        this.writeByteIndex += 4;
    }

    writeInt64(value: bigint) {
        this.extendIfNecessary(8);
        this.dataView.setBigInt64(this.writeByteIndex, value);
        this.writeByteIndex += 8;
    }

    writeUint8(value: number) {
        this.extendIfNecessary(1);
        this.dataView.setUint8(this.writeByteIndex, value);
        this.writeByteIndex += 1;
    }

    writeUint16(value: number) {
        this.extendIfNecessary(2);
        this.dataView.setUint16(this.writeByteIndex, value);
        this.writeByteIndex += 2;
    }

    writeUint32(value: number) {
        this.extendIfNecessary(4);
        this.dataView.setUint32(this.writeByteIndex, value);
        this.writeByteIndex += 4;
    }

    writeUint64(value: bigint) {
        this.extendIfNecessary(8);
        this.dataView.setBigUint64(this.writeByteIndex, value);
        this.writeByteIndex += 8;
    }

    writeFloat32(value: number) {
        this.extendIfNecessary(4);
        this.dataView.setFloat32(this.writeByteIndex, value);
        this.writeByteIndex += 4;
    }
    
    writeFloat64(value: number) {
        this.extendIfNecessary(8);
        this.dataView.setFloat64(this.writeByteIndex, value);
        this.writeByteIndex += 8;
    }

    writeBoolean(value: boolean) {
        this.writeUint8(+value);
    }

    writeNumber(value: number) {
        this.writeFloat64(value);
    }

    writeString(string: string) {
        this.writeUint16(string.length);

        for (let i = 0; i < string.length; ++i) {
            this.writeUint16(string.charCodeAt(i));
        }
    }

    writeEnum<T>(enumValues: readonly T[], value: T) {
        let index = enumValues.indexOf(value);

        if (index === -1) {
            throw new Error(`invalid enum value ${value}`);
        }

        this.writeUint16(index);
    }

    writeBuffer(buffer: Uint8Array) {
        this.writeUint32(buffer.length);
        this.extendIfNecessary(buffer.length);

        for (let i = 0; i < buffer.length; ++i) {
            this.dataView.setUint8(this.writeByteIndex + i, buffer[i]);
        }

        this.writeByteIndex += buffer.length;
    }

    readInt8(): number {
        let value = this.dataView.getInt8(this.readByteIndex);
        this.readByteIndex += 1;

        return value;
    }

    readInt16(): number {
        let value = this.dataView.getInt16(this.readByteIndex);
        this.readByteIndex += 2;

        return value;
    }

    readInt32(): number {
        let value = this.dataView.getInt32(this.readByteIndex);
        this.readByteIndex += 4;

        return value;
    }

    readInt64(): bigint {
        let value = this.dataView.getBigInt64(this.readByteIndex);
        this.readByteIndex += 8;

        return value;
    }

    readUint8(): number {
        let value = this.dataView.getUint8(this.readByteIndex);
        this.readByteIndex += 1;

        return value;
    }

    readUint16(): number {
        let value = this.dataView.getUint16(this.readByteIndex);
        this.readByteIndex += 2;

        return value;
    }

    readUint32(): number {
        let value = this.dataView.getUint32(this.readByteIndex);
        this.readByteIndex += 4;

        return value;
    }

    readUint64(): bigint {
        let value = this.dataView.getBigUint64(this.readByteIndex);
        this.readByteIndex += 8;

        return value;
    }

    readFloat32(): number {
        let value = this.dataView.getFloat32(this.readByteIndex);
        this.readByteIndex += 4;

        return value;
    }

    readFloat64(): number {
        let value = this.dataView.getFloat64(this.readByteIndex);
        this.readByteIndex += 8;

        return value;
    }

    readBoolean(): boolean {
        return !!this.readUint8();
    }

    readNumber(): number {
        return this.readFloat64();
    }

    readString(): string {
        let string = '';

        let length = this.readUint16();

        for (let i = 0; i < length; ++i) {
            string += String.fromCharCode(this.readUint16());
        }

        return string;
    }

    readEnum<T>(enumValues: readonly  T[]) {
        let index = this.readUint16();

        if (index >= enumValues.length) {
            throw new Error(`invalid enum index ${index}`);
        }

        return enumValues[index];
    }

    readBuffer(): Uint8Array {
        let length = this.readUint32();
        let result = new Uint8Array(length);

        for (let i = 0; i < length; ++i) {
            result[i] = this.dataView.getUint8(this.readByteIndex + i);
        }

        this.readByteIndex += length;

        return result;
    }

    private extendIfNecessary(requiredSize: number) {
        if (this.writeByteIndex + requiredSize > this.byteLength) {
            let newBuffer = new ArrayBuffer(Math.max(this.byteLength * 2, this.writeByteIndex + requiredSize));
            let uint8Array = new Uint8Array(newBuffer);

            uint8Array.set(this.asUint8Array());

            this.buffer = newBuffer;
            this.byteLength = this.buffer.byteLength;
            this.byteOffset = 0;
            this.dataView = new DataView(this.buffer);
        }
    }

    private asUint8Array(): Uint8Array {
        return new Uint8Array(this.buffer, this.byteOffset, this.byteLength);
    }

    sliceUint8Array(): Uint8Array {
        return new Uint8Array(this.buffer, this.byteOffset, this.writeByteIndex).slice();
    }

    asByteArray(): number[] {
        return [...new Uint8Array(this.buffer, this.byteOffset, this.byteLength)];
    }
}
globalThis.ALL_FUNCTIONS.push(FlexBuffer);