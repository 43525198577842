import seedrandom from 'seedrandom';
import { Constructor, FirstParameter, NonFunction, ObjectPredicate, Properties, SecondParameter } from '../../utils/language/types.ts';
import { Server } from '../server/server.ts';
import { Component } from '../component/component.ts';
import { CreateRoomParams, InteractionConcurrency, InteractionSource, QueueEventParams, RoomApiCapabilities, RoomApiResetParams, RoomApiState, WaitForServerResponseParams } from './room-api-types.ts';
import { RoomEvent } from './room-event.ts';
import { RoomManager } from './room-manager.ts';
import { RoomWrapper } from './room-wrapper.ts';
import { Collection, CollectionItem, collectionToArray, iterateCollection } from '../../utils/language/collection.ts';
import { ClientApiInteraction } from '../client/client-api-interaction.ts';
import { PromiseWithResolvers } from '../../utils/language/promise.ts';
import { GetComponentResolveType, PromptableComponent, isComponent } from '../component/component-types.ts';
import { GracefulAbort } from '../../utils/language/error.ts';
import { ClientData } from '../client/client-data.ts';
import { NATIVE_PROMPT_SCHEMA, getAnyDataSchema } from '../client/client-data-types.ts';
import { TypeSchemaLike } from '../../utils/type-schema/type-schema-like.ts';
import { ServerData } from '../server/server-data.ts';
import { RoomInfo } from './room-manager-types.ts';
import { ConfigureServerParams } from '../server/server-types.ts';
import { GenericButtonAction, GenericButtonCombination, UserInputTrigger, WaitForUserInputParams, getIsEnabledCallback } from '../user-input/user-input-types.ts';
import { USER_INPUT_SCHEMA, UserInput, UserInputData, unwrapUserInputData, wrapUserInput } from '../user-input/user-input.ts';
import { evalFunction } from '../../utils/language/function.ts';
import { LayerId } from '../graphics-engine/layer-types.ts';
import { ComponentModifier, componentModifierToCallback } from '../component/component-modifier.ts';
import { QueueId } from '../transition/transition-queue.ts';
import { ViewFragmentPriority } from '../view/view-types.ts';
import { EntityManager } from './entity-manager.ts';
import { GlobalContext, Wrapped } from '../global/global-context.ts';
import { applyObjectPredicate, applyObjectPredicateOrThrow } from '../../utils/language/object.ts';
import { getRandomNumber } from '../../utils/language/math.ts';
import { Counter } from '../../utils/data-structures/counter.ts';
import { PlayAudioParams } from '../animation/play-audio-animation/play-audio-params.ts';
import { Rect } from '../../utils/geometry/rect.ts';
import { View } from '../view/view.ts';
import { ReadSpreadsheetParams, ReadSpreadsheetResult } from '../../third-party/google/google-api-types.ts';
import { getJson } from '../../utils/http/http.ts';
import { RequestResponse } from '../../admin/request/request-types.ts';
import { SERVER_FULL_URL } from '../../admin/admin-public-constants.ts';
import { getSpreadsheetId } from '../../third-party/google/google-api-utils.ts';
import { FetchSpreadsheetRequest } from '../../admin/endpoints/endpoint-utils-spreadsheet.ts';

const SOURCE_ID_COUNTER: Counter = new Counter();
const DEFAULT_INTERACTION_CONCURRENCY: InteractionConcurrency = 'sequential';

/**
 * Set of methods passed to a {@link Component}'s methods, which give access to the framework's capabilities.
 * @category Core
 */
export class RoomApi extends ClientApiInteraction {
    private roomManager: RoomManager;
    private server: Server | null;
    private capabilities: RoomApiCapabilities = 'none';
    private roomWrapper!: RoomWrapper;
    private entityManager!: EntityManager;
    private sourceEntityId!: number | null;
    private interactionKey!: string | null;
    private interactionKind: InteractionConcurrency = DEFAULT_INTERACTION_CONCURRENCY;
    private event: object | null = null;
    private sourcePlayerId: string | null = null;
    private state: RoomApiState = RoomApiState.Ongoing;
    private sourceId: number = 0;
    private serverData: ServerData[] | null = null;
    private lastEventPromise: Promise<void> | null = null;
    private didUserInteractFlag: boolean = false;
    private hasStartedFlag: boolean = false;
    private onComplete: PromiseWithResolvers | null = null;
    private localServerResponse: boolean = false;
    private transitionQueueId: QueueId = null;
    private serverResponseRequested: boolean = false;
    private cache: { [key: string]: any; } = {};
    private rng: seedrandom.PRNG | null = null;
    private restartInteraction: (() => void) | null = null;
    private isFirstUserInput: boolean = true;
    private isLocal: boolean = false;
    private awaitedClientMessage: boolean = false;

    constructor(roomManager: RoomManager) {
        let client = roomManager.getClient();
        let server = roomManager.getServer();

        super(client);
        this.roomManager = roomManager;
        this.server = server;
    }

    reset(params: RoomApiResetParams): this {
        super.reset(params);
        this.capabilities = params.capabilities;
        this.roomWrapper = params.roomWrapper;
        this.entityManager = this.roomWrapper.getEntityManager();
        this.sourceEntityId = params.sourceEntityId ?? null;
        this.interactionKey = params.interactionKey ?? null;
        this.event = params.event ?? null;
        this.sourcePlayerId = params.sourcePlayerId ?? null;
        this.sourceId = SOURCE_ID_COUNTER.next();
        this.state = RoomApiState.Ongoing;
        this.serverData = params.serverData?.slice() ?? null;
        this.lastEventPromise = null;
        this.didUserInteractFlag = false;
        this.onComplete = params.onComplete ?? null;
        this.localServerResponse = false;
        this.hasStartedFlag = false;
        this.serverResponseRequested = false;
        this.interactionKind = DEFAULT_INTERACTION_CONCURRENCY;
        this.cache = {};
        this.rng = null;
        this.restartInteraction = params.restartInteraction ?? null;
        this.isFirstUserInput = true;
        this.isLocal = params.isLocal ?? false;
        this.awaitedClientMessage = false;

        return this;
    }

    destroy() {
        if (!this.preLoadedData && this.client) {
            this.client.cancelUserInput(this.sourceId);

            if (this.awaitedClientMessage) {
                this.client.cancelWaitForClientMessage(this.sourceId);
            }
        }

        super.destroy();
    }

    getId() {
        return this.roomWrapper.roomId;
    }

    getState(): RoomApiState {
        return this.state;
    }

    setAborted() {
        this.state = RoomApiState.Aborted;
    }

    getLocalPlayerId(): string | null {
        return this.roomManager.getLocalPlayerId();
    }

    getLocalPlayer<T extends Component>(clientConstructor?: Constructor<T>): T | null {
        let client = this.roomWrapper.players.get(this.getLocalPlayerId() ?? '');

        return applyObjectPredicate(client, clientConstructor);
    }

    isLocalPlayer(player: Component): boolean {
        return this.getLocalPlayer() === player;
    }

    getSourcePlayerId(): string {
        return this.sourcePlayerId ?? '';
    }

    getSourcePlayer<T extends Component>(clientConstructor?: Constructor<T>): T {
        let player = this.roomWrapper.players.get(this.sourcePlayerId ?? '');

        return applyObjectPredicateOrThrow(player, clientConstructor);
    }

    getEvent(): object | null {
        return this.event;
    }

    uuid(): string {
        return crypto.randomUUID();
    }

    getSourceId(): number {
        return this.sourceId;
    }

    emitRoomEvent(eventCallback: (api: RoomApi) => void): void;
    emitRoomEvent<T extends RoomEvent>(eventCallback: T, event: FirstParameter<T>, roomId?: string): void;
    emitRoomEvent<T extends RoomEvent>(eventCallback: T, event: FirstParameter<T> = undefined as any, roomId: string = this.roomWrapper.roomId): void {
        this.queueEvent({
            methodName: 'emitEvent',
            shouldWaitForCompletion: this.shouldWaitForCompletion(null, roomId),
            dataCallback: () => {},
            eventCallback: () => {
                return this.roomManager.queueEmitRoomEvent({
                    eventData: event,
                    eventPath: [],
                    eventCallback,
                    roomId,
                    serverData: null
                });
            }
        });
    }

    createRoom<R extends Constructor<Component, []>>(roomConstructor: R): string;
    createRoom<R extends Constructor<Component>>(roomConstructor: R, params: CreateRoomParams<R>): string;
    createRoom<R extends Constructor<Component>>(roomConstructor: R, params?: Partial<CreateRoomParams<R>>): string {
        let roomId = this.queueEvent({
            methodName: 'createRoom',
            shouldWaitForCompletion: false,
            dataCallback: (server) => server.generateRoomId(),
            eventCallback: (server, roomId) => {
                let room = new roomConstructor(...params?.constructorArgs ?? []);

                return this.roomManager.queueCreateRoom({ roomId, room });
            }
        });

        for (let [playerId, playerData] of params?.players ?? []) {
            this.addPlayerToRoom(roomId, playerId, playerData);
        }

        this.startRoom(roomId);

        return roomId;
    }

    deleteRoom(roomId: string) {
        return this.queueEvent({
            methodName: 'deleteRoom',
            shouldWaitForCompletion: this.shouldWaitForCompletion(null, roomId),
            dataCallback: () => {},
            eventCallback: () => this.roomManager.queueDeleteRoom({ roomId })
        });
    }

    startRoom(roomId: string) {
        return this.queueEvent({
            methodName: 'startRoom',
            shouldWaitForCompletion: this.shouldWaitForCompletion(null, roomId),
            dataCallback: () => {},
            eventCallback: () => this.roomManager.queueStartRoom({ roomId })
        });
    }

    addPlayerToRoom(roomId: string, playerId: string, player: Component) {
        return this.queueEvent({
            methodName: 'addClientToRoom',
            shouldWaitForCompletion: this.shouldWaitForCompletion(playerId, roomId),
            dataCallback: () => {},
            eventCallback: () => {
                let playerData = this.roomManager.getSerializer().serialize(player).sliceUint8Array();

                return this.roomManager.queueAddClientToRoom({ roomId, playerId, playerData });
            }
        });
    }

    removePlayerFromRoom(roomId: string, playerId: string) {
        return this.queueEvent({
            methodName: 'removeClientFromRoom',
            shouldWaitForCompletion: this.shouldWaitForCompletion(playerId, roomId),
            dataCallback: () => {},
            eventCallback: () => this.roomManager.queueRemoveClientFromRoom({ roomId, playerId })
        });
    }

    authenticatePlayer(currentPlayerId: string, newPlayerId: string) {
        return this.queueEvent({
            methodName: 'authenticateClient',
            shouldWaitForCompletion: this.getLocalPlayerId() === currentPlayerId || this.getLocalPlayerId() === newPlayerId,
            dataCallback: () => {},
            eventCallback: () => this.roomManager.queueAuthenticatePlayer({ currentPlayerId, newPlayerId })
        });
    }

    deauthenticatePlayer(playerId: string) {
        return this.queueEvent({
            methodName: 'deauthenticatePlayer',
            shouldWaitForCompletion: this.getLocalPlayerId() === playerId,
            dataCallback: () => {},
            eventCallback: (server) => {
                let newPlayerId = server.generateUuid();

                return this.roomManager.queueAuthenticatePlayer({ currentPlayerId: playerId, newPlayerId });
            }
        });
    }

    private queueEvent<T = void>(params: QueueEventParams<T>): T {
        let { methodName, shouldWaitForCompletion, eventCallback, dataCallback } = params;

        this.requireServer(methodName);

        let { eventId, data } = this.retrieveServerData(methodName, server => {
            let data = dataCallback(server);
            let eventId = eventCallback(server, data);

            return { eventId, data };
        });

        if (eventId && shouldWaitForCompletion) {
            this.lastEventPromise = this.roomManager.getEventResolvePromise(eventId);

            // console.log(`wait for: ${methodName}`);
            // this.lastEventPromise.then(() => console.log('ok: ' + methodName));
        }

        return data;
    }

    /**
     * Send the current interaction to the server, causing it to run the same `runInteraction` method on the same component server-side.
     * Items retrieved with {@link RoomApi.waitForUserInput} are sent via their index in `selectableComponents` and `shortcuts`.
     * 
     * If a callback is specified, it is run on the server only, and its return value is returned by `waitForServerResponse` to both the
     * server and the client.
     * 
     * This method the only way to send information from a client to the server. See {@link Component.runInteraction} for more information.
     * @param params 
     * @returns 
     */
    async waitForServerResponse<T = void>(params: (() => T | Promise<T>) | WaitForServerResponseParams<T> = {}): Promise<T> {
        if (!this.sourceEntityId || !this.interactionKey) {
            console.log(this.interactionKey);
            throw new Error('cannot call `waitForServerResponse` from a non-spawned component');
        }

        let formattedParams: WaitForServerResponseParams<T> = typeof params === 'function' ? { callback: params } : params;

        let {
            callback,
            local = false
        } = formattedParams;

        this.requireClient('waitForServerResponse', true);
        this.capabilities = 'server';
        this.hasStartedFlag = true;
        this.localServerResponse = local;
        this.serverResponseRequested = true;

        let result!: T;

        if (this.client && this.getLocalPlayerId() === this.sourcePlayerId && !this.serverData) {
            let clientData = (await Promise.all((this.savedData ?? []).map(async (data) => await data))).map(x => x) as ClientData[];

            let result = await this.client.sendInteractionToServer({
                clientData: clientData,
                entityId: this.sourceEntityId,
                interactionKey: this.interactionKey,
                roomId: this.roomWrapper.roomId,
                onComplete: this.onComplete!
            });

            if (!result.isOk()) {
                let errorMessage = result.errorMessage
                    ? `request failed: "${result.errorMessage}"`
                    : `request failed`;

                console.error(new Error(errorMessage));

                throw new GracefulAbort();
            }

            this.serverData = result.data!;

            result = this.loadServerData('response');
        } else if (this.server) {
            let data = await callback?.();

            this.storeServerData('response', data);

            result = data as T;
        } else {
            result = this.loadServerData('response');
        }

        return this.wrap(result);
    }

    async waitForLocalServerResponse<T = void>(callback?: () => T | Promise<T>): Promise<T> {
        return this.waitForServerResponse({ callback, local: true });
    }

    isLocalServerResponse(): boolean {
        return this.localServerResponse;
    }

    hasServerResponseBeenRequested(): boolean {
        return this.serverResponseRequested;
    }

    async waitForClientMessage<T>(kind: string, schema?: TypeSchemaLike<T>): Promise<T> {
        this.requireClient('waitForClientMessage', true);
        this.awaitedClientMessage = true;

        let restartInteraction = this.interactionKind === 'concurrent' ? this.restartInteraction : null;
        let result = this.retrieveClientData({
            kind: 'client-message',
            schema: schema ?? getAnyDataSchema(),
            callback: client => client.waitForClientMessage(this.sourceId, kind, restartInteraction)
        }) as Promise<any>;

        return this.wrapResult(result);
    }

    sendClientMessage<T>(kind: string, value: T) {
        if (!this.client || this.preLoadedData) {
            throw new Error(`can only send client message client-side`);
        }

        this.client.sendClientMessage(kind, value);
    }

    /**
     * Wait for the user to perform the specified action.
     * This method heavily relies on type inference to return the correct type based on what is specified.
     * You should not specify the type parameters yourself.
     * 
     * It must exclusively be called in {@link Component.runInteraction} and will throw an error if called anywhere else.
     * Check the {@link WaitForUserInputParams} documentation to have more details.
     * @param params 
     * @returns 
     * @example
     * class ChildComponent implements Component { ... }
     * 
     * class FirstComponent implements Component {
     *     items = {
     *         children: new ItemArray<ChildComponent>()
     *     }
     * 
     *     async runInteraction(api: ComponentApi): Promise<void> {
     *         // Wait for the user to click on a child
     *         let child = await api.waitForUserInput({
     *             selectableComponents: this.items.children.unwrap(),
     *         });
     * 
     *         // `child` as type `ChildComponent`
     *     }
     * }
     * 
     * class SecondComponent implements Component {
     *     async runInteraction(api: ComponentApi): Promise<void> {
     *         let input = await api.waitForUserInput({
     *             captureKeyboard: true
     *         });
     * 
     *         // `input` has type `KeyboardInput`
     *     }
     * }
     * 
     * class ThirdComponent implements Component {
     *     async runInteraction(api: ComponentApi): Promise<void> {
     *         let jump = { action: 'jump' };
     *         let shoot = { action: 'shoot' };
     * 
     *         let input = await api.waitForUserInput({
     *             shortcuts: {
     *                 'Space': jump,
     *                 'Enter': shoot,
     *             },
     *             captureScroll: true,
     *         });
     * 
     *         // `input` has type `ScrollInput | { action: string }`
     *     }
     * }
     */
    async waitForUserInput<
        A extends Collection<Component> = never,
        B = never,
        C extends Collection<Component> = never,
        D extends Collection<Component> = never,
    >(params: WaitForUserInputParams<A, B, C, D>): Promise<UserInput<CollectionItem<A> | B>> {
        this.requireClient('waitForUserInput', true);

        let isFirstUserInput = this.isFirstUserInput;

        this.didUserInteractFlag = true;
        this.isFirstUserInput = false;

        let fmtParams = (typeof params === 'function' ? { predicate: params } : params) as WaitForUserInputParams<A, B>;
        let result = await this.retrieveClientData<Promise<UserInputData>>({
            kind: 'user-input',
            schema: USER_INPUT_SCHEMA,
            callback: (client) => new Promise((resolve, reject) => {
                client.waitForUserInput({
                    sourceId: this.sourceId,
                    priority: this.getPriority(),
                    inputParams: fmtParams,
                    onComplete: (result) => {
                        if (result) {
                            resolve(wrapUserInput(this, result, fmtParams));
                        } else {
                            reject(new GracefulAbort());
                        }

                        if (isFirstUserInput && this.interactionKind === 'concurrent') {
                            return this.restartInteraction;
                        } else {
                            return null;
                        }
                    }
                });
            })
        });

        let userInput = unwrapUserInputData(this, result, fmtParams);
        let selectable = collectionToArray(evalFunction(fmtParams.selectable));
        let isEnabled = getIsEnabledCallback(fmtParams);

        if (fmtParams.checkIsEnabledOn === 'client') {
            isEnabled = () => true;
        }

        if (isComponent(userInput.selection) && selectable.includes(userInput.selection) && !isEnabled(userInput.selection)) {
            throw new GracefulAbort(`component not enabled`);
        }

        this.hasStartedFlag = true;

        return this.wrap(userInput);
    }

    async waitForShortcut<T>(shortcuts: Partial<{ [Key in GenericButtonCombination]: T }>, trigger?: Collection<UserInputTrigger>): Promise<UserInput<T>> {
        this.requireClient('waitForShortcut', true);

        return this.waitForUserInput<never, T>({ shortcuts, shortcutTrigger: trigger });
    }

    async waitForItemSelection<T extends Collection<Component>>(components: T | (() => T)): Promise<UserInput<CollectionItem<T>>> {
        this.requireClient('waitForItemSelection', true);

        return await this.waitForUserInput<T, never>({
            selectable: components,
        });
    }

    async waitForButton(
        button: Collection<GenericButtonCombination>,
        action: Collection<GenericButtonAction> = 'down',
        layerId?: LayerId,
    ): Promise<UserInput<never>> {
        this.requireClient('waitForButton', true);

        let actionList = collectionToArray(action);
        let buttonList = collectionToArray(button);

        return this.waitForUserInput<never, never>({
            layerId,
            predicate: (input) => {
                return actionList.includes(input.action as GenericButtonAction)
                    && buttonList.includes(input.combination);
            }
        });
    }

    async waitForScroll(): Promise<UserInput<never>> {
        this.requireClient('waitForScroll', true);

        return this.waitForUserInput({
            predicate: (input) => input.action === 'scroll'
        });
    }

    cancelAllUserInputs() {
        this.requireClient('cancelAllUserInputs', false);

        this.client!.cancelAllUserInputs();
    }

    /**
     * Prompt the user for some text using [window.prompt](https://developer.mozilla.org/en-US/docs/Web/API/Window/prompt).
     * If the user cancels the prompt, it returns `null`.
     * 
     * If `localStorageKey` is specified, stores the result in the [local storage](https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage).
     * The next time `prompt` is called wth the same `localStorageKey`, the method will return immediately with the stored value.
     * 
     * The method throws en error if is called server-side, unless it is in {@link Component.runInteraction}
     * _before_ a call to {@link RoomApi.waitForServerResponse}.
     * @param message 
     * @param localStorageKey 
     * @returns 
     */
    nativePrompt(message: string, localStorageKey?: string): string | null {
        this.requireClient('nativePrompt', false);

        return this.retrieveClientData({
            kind: 'native-prompt',
            schema: NATIVE_PROMPT_SCHEMA,
            callback: client => client.prompt(message, localStorageKey)
        });
    }

    getClientData<T>(callback: () => T, schema?: TypeSchemaLike<Awaited<T>>): T {
        this.requireClient('getClientData', false);

        let result = this.retrieveClientData({
            kind: 'client-data',
            schema: schema ?? getAnyDataSchema(),
            callback
        });

        if (result && result instanceof Promise) {
            return result.then(value => this.wrap(value)) as T;
        } else {
            return result;
        }
    }

    async getServerData<T>(callback: () => T, methodName?: string): Promise<T> {
        this.requireServer(methodName ?? 'getServerData');

        let result;

        if (this.server) {
            let data = await callback();

            this.storeServerData('getServerData', data);

            result = data;
        } else {
            result = this.loadServerData('getServerData');
        }

        return this.wrapResult(result);
    }

    async fetchGoogleSpreadsheet(spreadsheetUrl: string): Promise<ReadSpreadsheetResult> {
        return this.getServerData(async () => {
            let spreadsheetId = getSpreadsheetId(spreadsheetUrl);
            let response = await getJson<RequestResponse, FetchSpreadsheetRequest>(`${SERVER_FULL_URL}/utils/spreadsheet`, { spreadsheetId });

            if (response.code !== 200) {
                throw new Error(`invalid response code ${response.code}: ${response.error}`);
            }

            return response.data;
        }, 'fetchGoogleSpreadsheet');
    }

    withSourcePlayer(callback: () => void) {
        if (this.client && this.sourcePlayerId === this.getLocalPlayerId()) {
            callback();
        }
    }

    setQueue(queueId: QueueId): this {
        this.transitionQueueId = queueId;

        return this;
    }

    refresh(components?: Collection<Component>): this {
        if (!this.client) {
            return this;
        }

        let queue = this.client.getTransitionQueue(this.transitionQueueId);

        for (let item of iterateCollection(components ?? this.entityManager.getAll())) {
            let view = this.client.getView(item);

            if (!view) {
                continue;
            }

            view.setMinStartTime(queue.nextUpdateStartTime);
            view.setAnimationsEnabled(queue.animationEnabled);
            view.fillSelfFragment();
        }

        return this;
    }

    flash<T extends Component = Component>(
        component: Collection<T>,
        modifier?: ComponentModifier<T>
    ): this {
        if (!this.client) {
            return this;
        }

        let queue = this.client.getTransitionQueue(this.transitionQueueId);

        for (let item of iterateCollection(component)) {
            let view = this.client.updateView(item, null);

            if (!view.hasSelfFragment()) {
                view.setMinStartTime(queue.nextUpdateStartTime);
                view.setAnimationsEnabled(queue.animationEnabled);
                view.fillSelfFragment();
            }

            if (modifier) {
                view.setMinStartTime(queue.nextUpdateStartTime);
                view.setAnimationsEnabled(queue.animationEnabled);
                view.addFragment(ViewFragmentPriority.Flash, componentModifierToCallback(modifier as ComponentModifier<Component>));
            }

            queue.currentUpdateEndTime = Math.max(queue.currentUpdateEndTime, view.getTransitionEndTime());
            queue.nextUpdateStartTime = Math.max(queue.nextUpdateStartTime, view.getTransitionStartTime());
        }

        return this;
    }

    update<T extends Component = Component>(
        component: Collection<T>,
        callback?: (component: T, index: number) => (ComponentModifier<T> | ComponentModifier<T>[] | null | void | NonFunction),
        incrementalDelay?: number
    ): this {
        let components = collectionToArray(component);

        for (let i = 0; i < components.length; ++i) {
            let component = components[i];
            let modifier = callback?.(component, i);

            if (this.client) {
                let queue = this.client?.getTransitionQueue(this.transitionQueueId);
                let view = this.client.updateView(component, null);

                view.setMinStartTime(queue.nextUpdateStartTime);
                view.setAnimationsEnabled(queue.animationEnabled);
                view.fillSelfFragment();

                if (typeof modifier === 'function') {
                    view.addFragment(ViewFragmentPriority.Transition, componentModifierToCallback(modifier as ComponentModifier<Component>));
                }

                queue.currentUpdateEndTime = Math.max(queue.currentUpdateEndTime, view.getTransitionEndTime());
                queue.nextUpdateStartTime = Math.max(queue.nextUpdateStartTime, view.getTransitionStartTime()) + (incrementalDelay ?? 0);
            }
        }

        return this;
    }

    waitForAnimation(additionalDelay: number = 0): this {
        if (!this.client) {
            return this;
        }

        let queue = this.client.getTransitionQueue(this.transitionQueueId);

        queue.nextUpdateStartTime = queue.currentUpdateEndTime + additionalDelay;

        return this;
    }

    waitForDuration(durationMs: number): this {
        if (!this.client) {
            return this;
        }

        let queue = this.client.getTransitionQueue(this.transitionQueueId);

        queue.nextUpdateStartTime += durationMs;

        return this;
    }

    setAnimationsEnabled(enabled: boolean): this {
        if (!this.client) {
            return this;
        }

        let queue = this.client?.getTransitionQueue(this.transitionQueueId);

        queue.animationEnabled = enabled;

        return this;
    }

    run(callback: () => void): this {
        callback();

        return this;
    }

    playAudio(url: string, params: Omit<PlayAudioParams, 'audioUrl'> = {}) {
        this.now().playAudio({ audioUrl: url, ...params });
    }

    isClientConnected(clientId: string): boolean {
        return this.retrieveServerData('isClientConnected', server => server.isClientConnected(clientId));
    }

    isClientInRoom(clientId: string, roomId: string): boolean {
        return this.retrieveServerData('isClientInRoom', server => this.roomManager.isClientInRoom(clientId, roomId));
    }

    setConcurrency(interactionKind: InteractionConcurrency) {
        this.interactionKind = interactionKind;
    }

    getConcurrency(): InteractionConcurrency {
        return this.interactionKind;
    }

    /**
     * Returns whether the component's method is run on the client or the server.
     * @returns 
     */
    getExecutionContext(): 'client' | 'server' {
        if (this.client) {
            return 'client';
        } else {
            return 'server';
        }
    }

    /**
     * Indicates if the method is run on the client.
     * @returns 
     */
    isClientContext(): boolean {
        return !!this.client;
    }

    /**
     * Indicates if the method is run on the server.
     * @returns 
     */
    isServerContext(): boolean {
        return !!this.server;
    }

    getServerCurrentTime(): number {
        return this.retrieveCachedServerData('getServerCurrentTime', server => server.getClock().getCurrentTime());
    }

    getDeltaMs(): number {
        return this.retrieveCachedServerData('getServerTickDuration', server => server.getClock().getElapsedDurationSinceLastTick());
    }

    getDeltaSeconds(): number {
        return this.getDeltaMs() / 1000;
    }

    getRandomNumber(): number {
        if (this.isLocal) {
            return getRandomNumber();
        }

        if (this.rng === null) {
            let seed = 0;

            if (!this.serverData) {
                seed = this.retrieveClientData({
                    kind: 'random-number',
                    schema: 'number',
                    callback: () => getRandomNumber()
                });
            } else {
                seed = this.retrieveServerData('getRandomNumber', () => getRandomNumber());
            }

            this.rng = seedrandom(seed.toString());
        }

        return this.rng.quick();
    }

    async prompt<T extends PromptableComponent>(component: T, schema?: TypeSchemaLike<Awaited<GetComponentResolveType<T>>>): Promise<GetComponentResolveType<T>> {
        this.requireClient('prompt', true);

        return this.getClientData(async () => {
            this.flash(component);

            let client = this.client!;
            let data = await component.waitForData();

            client.destroyView(component);

            return data;
        }, schema);
    }

    assert(value: any) {
        if (!value) {
            throw new GracefulAbort();
        }
    }

    configureServer(params: ConfigureServerParams) {
        this.server?.configure(params);
    }

    didUserInteract(): boolean {
        return this.didUserInteractFlag;
    }

    hasStarted(): boolean {
        return this.hasStartedFlag;
    }

    getInteractionCompletionPromise(): Promise<void> | null {
        return this.lastEventPromise;;
    }

    getLoadedServerData(): ServerData[] {
        return this.serverData ?? [];
    }

    getRoomInfo(roomId: string): RoomInfo | undefined {
        return this.retrieveServerData('getRoomInfo', () => this.roomManager.getRoomInfo(roomId));
    }

    getAllRoomInfo(): RoomInfo[] {
        return this.retrieveServerData('getAllRoomInfo', () => this.roomManager.getAllRoomInfo());
    }

    get<T extends Component = Component>(constructor: Constructor<T> | null, predicate?: ObjectPredicate<T>): T {
        return this.entityManager.get(constructor, predicate);
    }

    getOptional<T extends Component = Component>(constructor: Constructor<T> | null, predicate?: ObjectPredicate<T>): T | undefined {
        return this.entityManager.getOptional(constructor, predicate);
    }

    getAll<T extends Component = Component>(
        constructor: Constructor<T> | null = null,
        predicate: ObjectPredicate<T> | null = null,
        maxCount: number = Infinity
    ): T[] {
        return this.entityManager.getAll(constructor, predicate, maxCount);
    }

    spawn<T extends Component>(entities: Collection<T>, parent?: Component): this {
        this.client?.scheduleRenderComponent(entities);

        for (let entity of iterateCollection(entities)) {
            this.entityManager.spawn(entity, parent ?? null, this);
        }

        return this;
    }

    despawn<T extends Component>(
        entities: Collection<T>,
        callback?: (component: T, index: number) => (ComponentModifier<T> | ComponentModifier<T>[] | null | void),
    ): this {
        for (let entity of iterateCollection(entities)) {
            this.entityManager.despawn(entity, this);
        }

        this.client?.scheduleUnmountComponent(entities);

        if (callback) {
            for (let entity of iterateCollection(entities)) {
                this.update(entity, callback);
            }
        }

        return this;
    }

    findParent<T extends Component = Component>(
        entity: Component,
        constructor: Constructor<T> | null = null,
        predicate: ObjectPredicate<T> | null = null,
    ): T | undefined {
        return this.entityManager.findParent(entity, constructor, predicate);
    }

    getParent<T extends Component = Component>(
        entity: Component,
        constructor: Constructor<T> | null = null,
        predicate: ObjectPredicate<T> | null = null,
    ): T {
        return this.entityManager.getParent(entity, constructor, predicate);
    }

    findAncestor<T extends Component = Component>(
        entity: Component,
        constructor: Constructor<T> | null = null,
        predicate: ObjectPredicate<T> | null = null,
    ): T | undefined {
        return this.entityManager.findAncestor(entity, constructor, predicate);
    }

    getAncestor<T extends Component = Component>(
        entity: Component,
        constructor: Constructor<T> | null = null,
        predicate: ObjectPredicate<T> | null = null,
    ): T {
        return this.entityManager.getAncestor(entity, constructor, predicate);
    }

    emitEvent<E extends RoomEvent>(evtFunction: E, ...args: Parameters<E>): this {
        this.entityManager.emitEvent(evtFunction, ...args);

        return this;
    }

    getViewRect(component: Component): Rect | undefined {
        return this.retrieveClientData({
            callback: client => client.getViewRect(component),
            kind: 'view-rect',
            schema: Rect.schema
        });
    }

    getView(component: Component): View | undefined {
        return this.client?.getView(component);
    }

    private requireOngoing() {
        if (this.state !== RoomApiState.Ongoing) {
            throw new Error(`RoomApi already destroyed`);
        }
    }

    private requireServer(methodName: string) {
        this.requireOngoing();

        if (!this.server && this.capabilities !== 'server') {
            throw new Error(`\`${methodName}\` can only be called in an event callback, or after calling \`waitForServerResponse\``);
        }
    }

    private requireClient(methodName: string, isUserInteraction: boolean) {
        this.requireOngoing();

        if (this.capabilities !== 'client') {
            throw new Error(`\`${methodName}\` can only be called in an interaction callback, and before calling \`waitForServerResponse\``);
        }

        if (isUserInteraction) {
            this.didUserInteractFlag = true;
        }
    }

    private storeServerData<T>(kind: string, data: T): T {
        if (!this.serverData) {
            this.serverData = [];
        }

        this.serverData.push({ kind, data });

        return data;
    }

    private loadServerData(kind: string): any {
        let item = this.serverData?.shift();

        if (!item) {
            if (this.serverData === null) {
                throw new Error(`server data: no more data to retrieve from (invalid api)`);
            }

            throw new Error(`server data: no more data to retrieve from`);
        } else if (item.kind !== kind) {
            throw new Error(`server data: expected "${kind}", got "${item.kind}"`);
        }

        return item.data;
    }

    private retrieveCachedServerData<T>(kind: string, callback: (server: Server) => T): T {
        if (kind in this.cache) {
            return this.cache[kind];
        } else {
            let result = this.retrieveServerData(kind, callback);

            this.cache[kind] = result;

            return result;
        }
    }

    private retrieveServerData<T>(kind: string, callback: (server: Server) => T): T {
        if (this.server) {
            let data = callback(this.server);
            this.storeServerData(kind, data);

            return data;
        } else {
            return this.loadServerData(kind);
        }
    }

    private shouldWaitForCompletion(clientId: string | null, roomId: string | null): boolean {
        let activeClientId = this.roomManager.getLocalPlayerId();
        let activeRoomWrapper = this.roomManager.getActiveRoomWrapper();

        if (!activeClientId) {
            return false;
        }

        if (clientId && clientId === activeClientId) {
            return true;
        }

        if (roomId && activeRoomWrapper?.roomId === roomId && activeRoomWrapper.players.has(activeClientId)) {
            return true;
        }

        return false;
    }

    private wrapResult<T>(value: T): T {
        if (value instanceof Promise) {
            return value.then(x => this.wrap(x)) as T;
        } else {
            return this.wrap(value);
        }
    }

    private wrap<T>(value: T): T {
        return {
            then: (onFullfilled: (value: T) => void) => {
                queueMicrotask(() => GlobalContext.api = this);
                onFullfilled(value);
            }
        } as T;

        // The loader in `src/cli/esbuild/plugins/code-injection.ts` will inject the relevant code to unwrap the value at build time
        return new Wrapped(this, value) as T;
    }
}
globalThis.ALL_FUNCTIONS.push(RoomApi);