import { Logger } from '../logging/logger.ts';
import { DataType, MAX_UINT_32 } from './serialization-constants.ts';
import { FlexBuffer } from './flex-buffer.ts';
import { SerializableAssetIndex, SerializableAssetIndexLike } from './serializable-asset-index.ts';
import { Serializable } from './serializable.ts';

export type DeserializerParams = {
    allowUnsafeFunctionDeserialization?: boolean
};

export class Deserializer {
    private serializableAssets: SerializableAssetIndex;
    private buffer!: FlexBuffer;
    private idToValue: Map<number, any> = new Map();
    private currentKey: string = '';
    private allowFunctionDeserialization: boolean;

    constructor(serializableAssets?: SerializableAssetIndexLike) {
        this.serializableAssets = SerializableAssetIndex.from(serializableAssets);
        this.allowFunctionDeserialization = false;
    }

    deserialize<T = any>(buffer: FlexBuffer | Uint8Array): T {
        this.currentKey = '';
        this.idToValue.clear();
        this.buffer = FlexBuffer.from(buffer);

        return this.readAny();
    }

    private readAny(): any {
        let dataType = this.buffer.readUint8() as DataType;

        if (dataType === DataType.Undefined) {
            return undefined;
        } else if (dataType === DataType.Null) {
            return null;
        } else if (dataType === DataType.True) {
            return true;
        } else if (dataType === DataType.False) {
            return false;
        } else if (dataType === DataType.Number) {
            return this.buffer.readFloat64();
        } else if (dataType === DataType.BigInt) {
            return this.buffer.readInt64();
        } else if (dataType === DataType.String) {
            return this.readString();
        } else if (dataType === DataType.Array) {
            return this.readArray();
        } else if (dataType === DataType.Object) {
            return this.readObject();
        } else if (dataType === DataType.StaticAsset) {
            return this.readStaticAsset();
        } else if (dataType === DataType.Buffer) {
            return this.readBuffer();
        } else if (dataType === DataType.Set) {
            return this.readSet();
        } else if (dataType === DataType.Map) {
            return this.readMap();
        } else if (dataType === DataType.Function) {
            return this.readFunction();
        } else if (dataType === DataType.Symbol) {
            return this.readSymbol();
        } else {
            this.warn('attempt to deserialize an invalid data type; this should not happen');
            return undefined;
        }
    }

    private readString(): string {
        let id = this.buffer.readUint32();
        let value = this.idToValue.get(id);

        if (value === undefined) {
            value = this.buffer.readString();
            this.idToValue.set(id, value);
        }

        return value;
    }

    private readArray<T = any>(): T[] {
        let id = this.buffer.readUint32();
        let value = this.idToValue.get(id);

        if (value === undefined) {
            let length = this.buffer.readUint32();
            value = new Array(length);
            this.idToValue.set(id, value);

            for (let i = 0; i < length; ++i) {
                let item = this.readAny();
                value[i] = item;
            }
        }

        return value;
    }

    private readObject<T extends object = any>(): T {
        let id = this.buffer.readUint32();
        let value = this.idToValue.get(id);

        if (value === undefined) {
            let classId = this.buffer.readUint32();
            let classConstructor = this.serializableAssets.getAssetFromId(classId);

            if (classId === MAX_UINT_32) {
                value = undefined;
                this.idToValue.set(id, value);
            } else {
                value = classConstructor ? Object.create(classConstructor.prototype) : {};

                let className = this.readString();

                if (!classConstructor && className) {
                    this.warn(`cannot construct object from non-registered constructor "${className}"`);
                }

                if ('deserialize' in value && typeof value.deserialize === 'function') {
                    (value as Serializable).deserialize(this.buffer);
                } else {
                    let propertyCount = this.buffer.readUint16();
                    this.idToValue.set(id, value);

                    for (let i = 0; i < propertyCount; ++i) {
                        let key = this.readString();
                        this.currentKey = key;
                        let item = this.readAny();

                        value[key] = item;
                    }
                }
            }
        }

        return value;
    }

    private readSet<T = any>(): Set<T> {
        let id = this.buffer.readUint32();
        let value = this.idToValue.get(id);

        if (value === undefined) {
            let array = this.readArray();

            value = new Set(array);
            this.idToValue.set(id, value);
        }

        return value;
    }

    private readMap<K = any, V = any>(): Map<K, V> {
        let id = this.buffer.readUint32();
        let value = this.idToValue.get(id);

        if (value === undefined) {
            let array = this.readArray();

            value = new Map(array);
            this.idToValue.set(id, value);
        }

        return value;
    }

    private readBuffer(): Uint8Array {
        let id = this.buffer.readUint32();
        let value = this.idToValue.get(id);

        if (value === undefined) {
            let byteSize = this.buffer.readUint32();
            value = new Uint8Array(byteSize);
            this.idToValue.set(id, value);

            for (let i = 0; i < byteSize; ++i) {
                value[i] = this.buffer.readUint8();
            }
        }

        return value;
    }

    private readStaticAsset(): any {
        let id = this.buffer.readUint32();

        return this.serializableAssets.getAssetFromId(id);
    }

    private readFunction(): any {
        let result: any = undefined;
        let assetId = this.buffer.readUint32();

        if (assetId > 0) {
            let index = this.buffer.readUint32();

            result = this.searchFunction(assetId, index);
        } else {
            // let id = this.buffer.readUint32();
            // result = this.idToValue.get(id);

            // if (!result) {
            //     let argCount = this.buffer.readUint32();
            //     let args: string[] = [];

            //     for (let i = 0; i < argCount; ++i) {
            //         args.push(this.buffer.readString());
            //     }

            //     let body = this.buffer.readString();
            //     result = new Function(...args, body);
            //     this.idToValue.set(id, result);
            // }
        }

        if (!this.allowFunctionDeserialization) {
            result = undefined;
        }

        if (result) {
            return result;
        }

        this.warn('cannot deserialize a non-registered function');

        return undefined;
    }

    private searchFunction(assetId: number, index: number): Function | undefined {
        let asset = this.serializableAssets.getAssetFromId(assetId);

        if (!asset) {
            return undefined;
        }

        let key = Object.getOwnPropertyNames(asset)[index];

        if (!key) {
            return undefined;
        }

        return asset[key];
    }

    private readSymbol(): any {
        this.warn('cannot deserialize a non-registered symbol');

        return undefined;
    }

    private warn(message: string) {
        let formatted = message;

        if (this.currentKey) {
            formatted = `field: "${this.currentKey}": ${formatted}`;
        }

        Logger.warn(formatted);
    }
}
globalThis.ALL_FUNCTIONS.push(Deserializer);