export class CallbackLoop {
    private callback: (elapsedMs: number) => void;
    private interval: number;
    private timer: NodeJS.Timeout | null = null;
    private lastUpdateTime: number = 0;

    constructor(callback: (elapsedMs: number) => void, interval: number) {
        this.callback = callback;
        this.interval = interval;
    }

    start() {
        if (this.timer) {
            return;
        }

        this.lastUpdateTime = performance.now();

        let update = () => {
            let now = performance.now();
            let elapsed = now - this.lastUpdateTime;

            this.lastUpdateTime = now;
            this.callback(elapsed);
            this.timer = setTimeout(update, this.interval);
            
        };

        this.timer = setTimeout(update, this.interval);
    }

    stop() {
        if (!this.timer) {
            return;
        }

        clearTimeout(this.timer);
        this.timer = null;
    }

    setInterval(duration: number) {
        this.interval = duration;
    }
}
globalThis.ALL_FUNCTIONS.push(CallbackLoop);