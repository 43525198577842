import { GetParameters, GetReturnType, Methods } from '../../utils/language/types.ts';

const HOOKS = {
    enabled: true,
    originals: [] as [any, string, any][],
};

export function enableGlobalHooks() {
    if (!HOOKS.enabled) {
        return;
    }

    enableHook(Math, 'random', (random) => {
        console.warn([
            'Using Math.random() can cause desynchronizations between the client and the server.',
            'Consider using `Room.getRandomNumber()` instead.',
            'If you know what you are doing, you can disable this warning with `Outpost.disableGlobalHooks()`.'
        ].join(' '));

        return random();
    });
}

export function disableGlobalHooks() {
    for (let [root, name, original] of HOOKS.originals) {
        root[name] = original;
    }

    HOOKS.enabled = false;
    HOOKS.originals = [];
}

function enableHook<T extends object, K extends keyof Methods<T> & string>(root: T, name: K, hook: (original: Methods<T>[K], ...args: GetParameters<Methods<T>[K]>) => GetReturnType<Methods<T>[K]>) {
    let original = root[name];

    HOOKS.originals.push([root, name, original]);

    root[name] = ((...args: any[]) => hook(original, ...args as GetParameters<Methods<T>[K]>)) as any;
}