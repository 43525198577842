import { TypeKind, TypeSchema } from './type-schema.ts';

export function enumSchema<T>(enumValues: readonly T[]): TypeSchema<T> {
    return {
        kind: TypeKind.Enum,
        optional: false,
        check(ctx, value) {
            let ok = enumValues.includes(value as any);

            if (ok) {
                return true;
            }

            let expected: string;

            if (enumValues.length === 1) {
                expected = `"${enumValues[0]}"`;
            } else {
                expected = `one of ${enumValues.map(string => `"${string}"`).join(', ')}`;
            }

            return ctx.expected(expected, value);
        },
        serialize(buffer, value) {
            buffer.writeEnum(enumValues, value)
        },
        deserialize(buffer) {
            return buffer.readEnum(enumValues);
        },
    };
}