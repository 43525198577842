import { startGameHub } from '../../hub/hub.ts';
import { startBot } from '../bot/start-bot.ts';
import { startGame } from '../start-game.ts';
import { disableGlobalHooks } from './global-hooks.ts';

export const Outpost = {
    startGame,
    startBot,
    startGameHub,
    disableGlobalHooks,
};