/**
 * Represents an horizontal alignment.
 */
export type HorizontalAlign =
    | 'left'
    | 'center'
    | 'right'

export function getHorizontalAlignMultiplier(horizontalAlign: HorizontalAlign | number): number {
    switch (horizontalAlign) {
        case 'center': return 0.5;
        case 'left': return 0;
        case 'right': return 1;
        default: return horizontalAlign;
    }
}