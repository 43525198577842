import { Constructor } from '../../utils/language/types.ts';
import { FlexBuffer } from '../../utils/serialization/flex-buffer.ts';
import { ComponentHookMethodName, RoomHookMethodName } from '../component/component-types.ts';
import { Component } from '../component/component.ts';
import { GlobalContext } from '../global/global-context.ts';
import { EntityManager } from './entity-manager.ts';
import { RoomApi } from './room-api.ts';
import { AggregatedServerData, CreateRoomParams, RoomInfo, SerializedRoomSnapshot } from './room-manager-types.ts';
import { RoomManager } from './room-manager.ts';

export class RoomWrapper {
    room: Component;
    roomId: string;
    players: Map<string, Component> = new Map();

    private roomManager: RoomManager;
    private defaultRoomApi: RoomApi;
    private entityManager: EntityManager = new EntityManager();

    constructor(roomManager: RoomManager, params: CreateRoomParams) {
        this.roomManager = roomManager;
        this.roomId = params.roomId;
        this.room = params.room;
        this.defaultRoomApi = new RoomApi(roomManager);

        for (let [clientId, clientData] of params.players ?? []) {
            this.players.set(clientId, clientData);
        }

        if (params.entitiesSnapshot) {
            this.entityManager.initFromSnapshot(params.entitiesSnapshot);
        }
    }

    static fromSnapshot(roomManager: RoomManager, snapshot: SerializedRoomSnapshot) {
        let deserializer = roomManager.getDeserializer();
        let createParams = deserializer.deserialize(new FlexBuffer(snapshot));

        return new RoomWrapper(roomManager, createParams);
    }

    matchesConstructor(roomConstructor: Constructor<Component> | undefined): boolean {
        return !roomConstructor || this.room instanceof roomConstructor;
    }

    getSnapshot(): SerializedRoomSnapshot {
        let serializer = this.roomManager.getSerializer();
        let createParams: CreateRoomParams = {
            room: this.room,
            roomId: this.roomId,
            players: [...this.players.entries()],
            entitiesSnapshot: this.entityManager.getSnapshot()
        };

        return serializer.serialize(createParams).sliceUint8Array();
    }

    spawn(entity: Component) {
        this.resetRoomApi().spawn(entity);
    }

    despawn(entity: Component) {
        this.resetRoomApi().despawn(entity);
    }

    private resetRoomApi(): RoomApi {
        return this.defaultRoomApi.reset({
            roomWrapper: this,
            capabilities: 'server'
        });
    }

    runHookMethod<K extends ComponentHookMethodName>(
        aggregatedServerData: AggregatedServerData | null,
        methodName: K,
        sourcePlayerId: string | null
    ): boolean {
        let key = `${this.roomId}_${methodName}`;
        let api = this.defaultRoomApi.reset({
            roomWrapper: this,
            capabilities: 'server',
            serverData: aggregatedServerData?.[key] ?? [],
            sourcePlayerId
        });

        this.entityManager.forEach(entity => GlobalContext.runComponentMethod(api, entity, methodName))

        if (aggregatedServerData && !aggregatedServerData[key]) {
            aggregatedServerData[key] = api.getLoadedServerData();
        }

        return true;
    }

    runRoomHookMethod<K extends RoomHookMethodName>(
        aggregatedServerData: AggregatedServerData | null,
        methodName: K,
        roomId: string,
        sourcePlayerId: string | null
    ): void {
        let key = `${this.roomId}_${methodName}`;
        let api = this.defaultRoomApi.reset({
            roomWrapper: this,
            capabilities: 'server',
            serverData: aggregatedServerData?.[key],
            sourcePlayerId
        });

        GlobalContext.runCallback(api, () => this.room[methodName]?.(roomId));

        if (aggregatedServerData && !aggregatedServerData[key]) {
            aggregatedServerData[key] = api.getLoadedServerData();
        }
    }

    getInfo(): RoomInfo {
        return {
            roomId: this.roomId,
            roomConstructor: this.room.constructor as Constructor<Component>,
            playerIds: [...this.players.keys()],
        };
    }

    getEntityManager(): EntityManager {
        return this.entityManager;
    }
}
globalThis.ALL_FUNCTIONS.push(RoomWrapper);