import toml from 'toml';
import { ParseContext } from '../parse-context.js';
import { parseFile } from '../parse-file.js';
import { ParseResult } from '../parse-result.js';
import { ParseStringParams, parseString } from '../parse-string.js';
import { TypeSchema } from '../../type-schema/type-schema.ts';

export type ParseTomlResult<T> = ParseResult<T>;
export type ParseTomlParams<T> = {
    schema: TypeSchema<T>;
    keyMapping?: ParseStringParams<T>['keyMapping'];
};

function parseToml(content: string, ctx: ParseContext): ParseResult<unknown> {
    // TODO: handle errors

    let data: any = undefined;
    try {
        data = toml.parse(content);
    } catch (err) {
        return ctx.toParseError('invalid JSON');
    }

    let value = replaceKeys(data);

    return new ParseResult({ value });
}

export function parseTomlContent<T>(content: string, params: ParseTomlParams<T>): ParseTomlResult<T> {
    return parseString({
        parse: parseToml,
        content,
        ...params
    });
}

export function parseTomlFile<T>(filePath: string, params: ParseTomlParams<T>): ParseTomlResult<T> {
    return parseFile({
        parse: parseToml,
        filePath,
        ...params
    });
}

export function parseOptionalTomlFile<T>(filePath: string, params: ParseTomlParams<T>): ParseTomlResult<T> {
    return parseFile({
        parse: parseToml,
        filePath,
        defaultContent: '',
        ...params,
    });
}

function replaceKeys(value: any): any {
    if (Array.isArray(value)) {
        return value.map(item => replaceKeys(item));
    } else if (value && typeof value === 'object') {
        let result: any = {};

        for (let [key, prop] of Object.entries(value)) {
            result[formatKey(key)] = replaceKeys(prop);
        }

        return result;
    } else {
        return value;
    }
}

function formatKey(string: string): string {
    return string
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/-[a-z]/g, str => str[1].toUpperCase());
}