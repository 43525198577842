import { registerSerializableAsset } from '../framework/builtin-serializable-assets.ts';
import { Component } from '../framework/component/component.ts';
import { ConfigureRendererParams } from '../framework/graphics-engine/graphics-engine-types.ts';
import { startGame } from '../framework/start-game.ts';
import { View } from '../framework/view/view.ts';
import { TextBoxStyle } from '../helpers/widgets/textbox.ts';
import { WidgetStyle } from '../helpers/widgets/widget-types.ts';
import { ColorLike } from '../utils/color/color.ts';
import { NumberRange } from '../utils/language/range.ts';
import { Constructor } from '../utils/language/types.ts';
import { HubRoom } from './hub-room/hub-room.ts';
import { LobbyRoom } from './lobby-room/lobby-room.ts';
import { LoginRoom } from './login-room/login-room.ts';

export type GameParams = {
    dataSpreadsheet: { name: string; values: string[][]; }[];
};

export type PlayerParams = {
    id: string;
    color: ColorLike;
};

export type GameHubParams = {
    gameConstructor: Constructor<Component, [GameParams]>;
    playerConstructor: Constructor<Component, [PlayerParams]>;
    playerCount?: NumberRange;
    matchmaking?: boolean;
    gameName?: string;
    autoLogin?: boolean;
    autoJoin?: boolean;
    renderer?: ConfigureRendererParams;
    buttonStyle?: WidgetStyle;
    textBoxStyle?: TextBoxStyle;
    renderLoginRoom?: (view: View, loginRoom: LoginRoom) => void;
    renderHubRoom?: (view: View, hubRoom: HubRoom) => void;
    renderLobbyRoom?: (view: View, lobbyRoom: LobbyRoom) => void;

    gearName?: string;
    gearCategories?: GearCategory<Component>[];
    defaultGearSets?: DefaultGearSet[];
    dataSpreadsheetUrl?: string;
};

export type GearCategory<T extends Component = Component> = {
    name: string,
    availableSectionName?: string;
    selectedSectionName?: string;
    kind: GearCategoryKind;
    selectable: T[];
    itemMaxSelectableCount?: number | ((item: T) => number);
    requiredSelectedItemCount?: NumberRange;
};

export type DefaultGearSet = {
    name: string;
    items: Component[];
};

export type GearCategoryKind = 'deck' | 'item';

export function startGameHub(params: GameHubParams) {
    registerSerializableAsset(params);

    for (let category of params.gearCategories ?? []) {
        for (let item of category.selectable) {
            registerSerializableAsset(item);
        }
    }

    startGame(() => new LoginRoom(params));
}