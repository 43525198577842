import { Vector } from '../../utils/geometry/vector.ts';
import { anySchema } from '../../utils/type-schema/any-schema.ts';
import { nullableSchema } from '../../utils/type-schema/nullable-schema.ts';
import { TypeSchema } from '../../utils/type-schema/type-schema.ts';
import { getSerializableAssets } from '../builtin-serializable-assets.ts';

export const DRAG_OFFSET_SCHEMA = nullableSchema(Vector.schema);
export const NATIVE_PROMPT_SCHEMA = nullableSchema<string | null>('string');

let globalAnyDataSchema: TypeSchema<any> | null = null;

export function getAnyDataSchema(): TypeSchema<any> {
    if (!globalAnyDataSchema) {
        globalAnyDataSchema = anySchema(getSerializableAssets());
    }

    return globalAnyDataSchema;
}