import { objectSchema } from '../../utils/type-schema/object-schema.ts';
import { Rect } from '../../utils/geometry/rect.ts';
import { nullableSchema } from '../../utils/type-schema/nullable-schema.ts';
import { enumSchema } from '../../utils/type-schema/enum-schema.ts';
import { WEBGL_BLEND_FUNCTIONS_ENUM, WebglBlendFunction } from '../../utils/webgl/webgl-blend-function.ts';
import { WEBGL_TEXTURE_FILTER_ENUM, WebglTextureFilter } from '../../utils/webgl/webgl-texture-filter.ts';

export type LayerId = string | number | null;

/**
 * Scene properties, returned by {@link ComponentApi.getSceneProperties}.
 * @see {@link DEFAULT_VIRTUAL_VIEWPORT}
 */
export type LayerProperties = {
    /**
     * Id of the scene.
     */
    id: LayerId;
    /**
     * Area of the virtual viewport that the scene covers. By default, the scene covers the whole viewport.
     */
    viewport: Rect;
    /**
     * X coordinate of what is displayed at the center of the scene rectangle.
     * When the scene is created (via {@link AnimationQueue.updateScene}), it is initialized to half of the virtual viewport's width.
     */
    cameraX: number;
    /**
     * Y coordinate of what is displayed at the center of the scene rectangle.
     * When the scene is created (via {@link AnimationQueue.updateScene}), it is initialized to half of the virtual viewport's height.
     */
    cameraY: number;
    /**
     * Ratio by which the scene zooms on its center. Default: 1.
     */
    cameraZoom: number;
    /**
     * Whether to display the scene or not.
     */
    zIndex: number;
    shown: boolean;
    blending: WebglBlendFunction;
    textureFilter: WebglTextureFilter;
};

export const LAYER_PROPERTIES_SCHEMA = objectSchema<LayerProperties>({
    id: nullableSchema('string'),
    viewport: Rect.schema,
    cameraX: 'number',
    cameraY: 'number',
    cameraZoom: 'number',
    zIndex: 'number',
    shown: 'boolean',
    blending: enumSchema(WEBGL_BLEND_FUNCTIONS_ENUM),
    textureFilter: enumSchema(WEBGL_TEXTURE_FILTER_ENUM),
});