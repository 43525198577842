/**
 * Represents a vertical alignment.
 */
export type VerticalAlign =
    | 'top'
    | 'middle'
    | 'bottom';

export function getVerticalAlignMultiplier(verticalAlign: VerticalAlign | number): number {
    switch (verticalAlign) {
        case 'middle': return 0.5;
        case 'top': return 0;
        case 'bottom': return 1;
        default: return verticalAlign;
    }
}