import { ParseContext } from '../parsing/parse-context.ts';
import { FlexBuffer } from '../serialization/flex-buffer.ts';
import { Logger } from '../logging/logger.ts';
import { TypeSchemaLike, formatTypeSchema } from './type-schema-like.ts';
import { Result } from '../language/result.ts';

export enum TypeKind {
    Boolean,
    Number,
    String,
    Enum,
    Other
}

export type TypeSchema<T> = {
    optional?: boolean;
    kind?: TypeKind;
    check(ctx: ParseContext, value: unknown): boolean;
    serialize(buffer: FlexBuffer, value: T): void;
    deserialize(buffer: FlexBuffer): T;
};

export function serializeValue<T>(type: TypeSchemaLike<T>, buffer: FlexBuffer, value: T): FlexBuffer {
    let formatted = formatTypeSchema(type);

    formatted.serialize(buffer, value);

    return buffer;
}

export function deserializeValue<T>(type: TypeSchemaLike<T>, buffer: FlexBuffer): T {
    let formatted = formatTypeSchema(type);

    return formatted.deserialize(buffer);
}

export function checkValue<T>(type: TypeSchemaLike<T>, ctx: ParseContext, value: T): boolean {
    return formatTypeSchema(type).check(ctx, value);
}

export function deserializeValueSafe<T>(type: TypeSchemaLike<T>, buffer: FlexBuffer): Result<T> {
    try {
        return Result.ok(deserializeValue(type, buffer));
    } catch (error: unknown) {
        Logger.error(error);
        return Result.error();
    }
}